body {
  background-color: #eff4f8;
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

code {
  font-family: "DM Sans", sans-serif;
}

.page-title {
  padding: 30px 0;
  // font-family: 'DM Sans';
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 38px !important;
  line-height: 49px !important;
  text-align: center !important;
  text-transform: uppercase;
}

.border-radius {
  border-radius: $border-radius !important;
}

.menu-title {
  margin: 10px 5px 4px;
  color: #818181;
}

.menu-clopsed {
  .menu-title {
    display: none;
  }
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .navbar-brand {
    img {
      max-width: 250px;
    }
  }
}

.background-white {
  background-color: #ffffff;
}

.page-content {
  &.mobile-view {
    margin-top: 95px;

    .background-white.container-min-height {
      min-height: calc(100vh - 95px);
    }
  }

  &.desktop-view {
    margin-top: 55px;

    .background-white.container-min-height {
      min-height: calc(100vh - 55px);
    }
  }
}

.list-group-item:first-child {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.list-group-item:last-child {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.profile.dropdown-menu {
  z-index: 1050 !important;
}

.badge-notification {
  padding: 0.35em !important;
  line-height: 0.5 !important;
  margin-left: -7px !important;
}

.filter .active {
  background-color: #7367f0 !important;
  color: #fff !important;
}

.how-to-list {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  padding: 0 10px;
}

// .how-to-form-term-box .form-check-label {
//   margin-left: 10px;
// }

// Start photo pace style
.add-photo-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  .selected-img {
    margin-bottom: 15px;
    width: 130px;
    height: 130px;
    object-fit: contain;
  }

  .photo-box-title {
    margin-bottom: 10px;
  }
}

// Photo Modal
.modal-description-div {
  display: flex;
  justify-content: center;
}
.modal-description {
  max-width: 300px;
  width: 100%;
}

.add-additional-photo-box {
  display: flex;
  justify-content: center;

  .add-additional-photo {
    padding: 4rem 1rem;
    cursor: pointer;
    border: 1px solid #eee;
    max-width: 200px;
    text-align: center;
    min-width: 180px;
    border-radius: 3px;
    transition: all 0.3s;

    &:hover {
      border: 1px solid #d6d4d4;
    }

    .icon {
      margin-bottom: 10px;
    }
  }
}
.label-round-btn:active,
.label-round-btn:hover,
.label-round-btn:focus,
.label-round-btn {
  border: 1px solid !important;
}
// End photo pace style

// Start articles pages
.add-article-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  max-width: 350px;
  height: 130px;
  margin: auto;
  border: 1px solid #d2d2d2;
  border-radius: $border-radius;
  cursor: pointer;

  .icon {
    margin-bottom: 10px;
  }
}
// End articles pages

// Start loader
.content-middle {
  min-height: calc(100vh - 150px);
  display: flex;
  justify-content: center;
  align-items: center;
}
// End loader

// Start loader
.content-middle-sm {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
// End loader

// requestDetaisl page imgae icon style start

.cross-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
}
.check_icon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
}
// requestDetaisl page imgae icon style end

// Start my request status style
.req-state-inprogress {
  color: #f6aa37;
}

.req-state-picture {
  color: #ff4259;
}

.req-state-open {
  color: #59be9a;
}

.req-state-red {
  color: #ff4259;
  background-color: #fcc1c8a6;
  padding: 3px 15px 5px 15px;
  font-weight: 500;
  border-radius: 10px;
  margin: 15px 0;
}

.req-state-blue {
  color: #43b48b;
  background-color: #c8ffeb;
  padding: 3px 15px 5px 15px;
  font-weight: 500;
  border-radius: 10px;
  margin: 15px 0;
}
.req-state-gray {
  color: #212121;
  background-color: #e0dede;
  padding: 3px 15px 5px 15px;
  font-weight: 500;
  border-radius: 10px;
  margin: 15px 0;
}
.req-state-black {
  color: #ffff;
  background-color: black;
  padding: 3px 15px 5px 15px;
  font-weight: 500;
  border-radius: 10px;
  margin: 15px 0;
}
.req-state-green {
  color: #43B48B;
  background-color: rgba(200, 255, 235, 0.78);
  padding: 3px 15px 5px 15px;
  font-weight: 500;
  border-radius: 10px;
  margin: 15px 0;
}

.req-state-orange {
  display: flex;
  align-items: center;
  color: #ffff;
  background-color: #fbd04f;
  padding: 3px 15px 5px 15px;
  font-weight: 500;
  border-radius: 10px;
  margin: 15px 0;
}
.req-state-orange img {
  height: 15px;
  margin-right: 8px;
  margin-top: 3px;
}

.req-state-white {
  color: #ffffff;
  background-color: #59be9a;
  padding: 3px 15px 5px 15px;
  font-weight: 500;
  margin: 15px 0;
  border-radius: 10px;
}
.req-state-white img {
  height: 15px;
}
// End my request status style

// Start image uploader style
.img-upload-loader {
  min-width: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000014;
  border-radius: 3px;
  margin-bottom: 15px;
}
// End image uploader style

//Start Stepper styles
.stepper-container-box {
  // text-align: center;

  .stepper-content {
    margin: 2rem 0 0;
  }
}

.request-create-stepper {
  padding: 24px 5px !important;
  // .brand-step.active {
  //   background-color: #8E63FF !important;
  // }

  #RFS-StepMain {
    flex-direction: column-reverse;

    #RFS-Label {
      margin-bottom: 16px;
      margin-top: 0px;
    }
  }

  #RFS-ConnectorContainer {
    top: auto;
    bottom: calc((2em - 1px) / 2);
  }
}
//End Stepper styles

// Start payout stepper styles
.voucher-box {
  display: flex;
  flex-basis: auto;
  justify-content: space-between;
  margin: 3rem 0;
  flex-wrap: wrap;
}
// End payout stepper styles

body.buddyandselly .navbar-brand,
body.buddyandselly .iframe-hide {
  display: none !important;
}

.card-body {
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.46);
  border-radius: 30px;
}


.how-to-article-box { 
  margin-left: 30px;
}

.how-to-form-term-box { 
  margin-top: 3rem; 
}

.form-check-input:checked { 
  background: #6200EE; 
}
  
.row-margin-custom {
  margin: 0 -15px !important;
}

.background-white.container-fluid {
  max-width: 1440px;
  margin: 0 auto;
}