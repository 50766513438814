.small_input {
  height: 10px;
  line-height: 10px;
  font-size: 8pt;
  min-height: 30px !important;
}

.form-required-icon {
  color: #dd0303;
  margin-left: 2px;
}

.form-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 5px 15px;

  &:active,
  &:focus {
    color: #212529;
    background-color: #fff;
    border-color: $color-blue;
    outline: 0;
    box-shadow: 0 0 0px 0.75px $color-blue;
  }
}

.form-action-field {
  display: flex;
  justify-content: space-between;

  .form-field {
    flex-basis: 100%;
  }

  .form-action-btn {
    flex-basis: 32px;
    text-align: center;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s;
    margin-left: 10px;
    margin-top: 5px;

    &:hover {
      opacity: 1;
    }
  }
}

//React Select sm Styles Starts
.react-select-sm .css-319lph-ValueContainer {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.react-select-sm .css-6j8wv5-Input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.react-select-sm .css-tlfecz-indicatorContainer,
.react-select-sm .css-1gtu0rj-indicatorContainer {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.react-select-sm .css-1s2u09g-control,
.react-select-sm .css-1pahdxg-control {
  min-height: 30px;
}
//React Select sm Styles Ends

// Start select styles
.brand-search-field {
  .css-13cymwt-control:active,
  .css-13cymwt-control:focus,
  .css-13cymwt-control:hover,
  .css-t3ipsp-control,
  .css-13cymwt-control {
    border-radius: 30px;
    padding: 0 10px;

  }
}

  input.form-control:focus,
  input.form-control:active {
    outline: none !important;
  }

  .invalid-text {
    font-size: small;
    color: red;
    margin-left: 15px;
    display: none;
  }

  .invalid {
    .form-control {
      border-color: red;
        border-right-color: transparent;
    }
  
    .input-group-text {
      border-color: red;
        border-left-color: transparent;
    }

    .invalid-text {
      display: block;
    }

    .uploader-box {
      margin-bottom: 5px;
    }
  }  

// End select styles