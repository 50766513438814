.cart {
  &_clear_btn {
    background-color: $color-red;
    border: none;
    width: 100%;
    &:hover {
      background-color: $color-gray-red;
    }
  }
  &_placeorder_btn {
    border: none;
    width: 100%;
    background-color: $Green-Peas;
  }
  &_invoice_btn {
    width: 100%;
    background-color: $Greyish-Turquoise;
  }
}
.btn-primary {
  background-color: #ffa079;
  border: none;
  &:hover {
    background-color: $primary-color;
    box-shadow: 0px 8px 15px rgba($color-black, 0.29);
  }
  &:active {
    transform: translateY(2px);
  }
}

.btn-outline-primary {
  --bs-btn-color: #7367f0;
  --bs-btn-border-color: #7367f0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #7367f0;
  --bs-btn-hover-border-color: #7367f0;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #7367f0;
  --bs-btn-active-border-color: #7367f0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7367f0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7367f0;
  --bs-gradient: none;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #7367f0;
  --bs-btn-border-color: #7367f0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #557db8;
  --bs-btn-hover-border-color: #557db8;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #7367f0;
  --bs-btn-active-border-color: #7367f0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #7367f0;
  --bs-btn-disabled-border-color: #7367f0;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: $primary-color;
  border-color: $primary-color;
}

.cart_placeorder_btn.btn:hover,
.cart_placeorder_btn.btn:disabled,
.cart_placeorder_btn.btn.disabled {
  background-color: #8bc34ac2 !important;
  border-color: #8bc34ac2 !important;
}

.fa-circle-xmark {
  color: $color-gray-red;
  &:hover {
    color: $color-red;
  }
}

.btn-light {
  background-color: #ecf0f1;
  color: #000;
}
.green-btn{
  background: #6BDB91;
  color: #fff;
  border-radius: 30px!important;
  font-size: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
}
.green-btn:hover{
  background: #5ebf7e;
  color: #fff;
}


.round-btn,
.nav-round-btn {
  border-radius: 30px!important;
  font-size: 18px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 15px;
}
.round-btn:hover{
  background: #425b52;
  border: 1px solid #425b52;
  color: white;
}
.google_login_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 53px;
  // width: 253px;

  width: 100%;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  background: #fff;
  box-shadow: 0px 1px 3px 0px #00000075;
  img{
    margin-right: 10px;
    justify-self: start;
  }
}
.apple_login_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 53px;
  // width: 253px;
  width: 100%;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: white;
  background: #000;
  box-shadow: 0px 1px 3px 0px #00000075;

  img{
    margin-right: 10px;
  }
}

.facebook_login_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 53px;
  // width: 253px;
  width: 100%;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: white;
  background: #29487D;
  box-shadow: 0px 1px 3px 0px #00000075;
  img{
    margin-right: 10px;
  }
}


.border_btn{
  width: 245px;
  height: 64px;
  border-radius: 30px;
  text-align: center;
  background: transparent;
  font-weight: bold;
  border: 1px solid black;
}

.underline_btn{
  border: none;
  color: #8E63FF;
  font-weight: bold;
  padding-bottom: 5px;
  background: transparent;
  text-decoration: underline;
}