.article-card {
    border-radius: $border-radius;
    margin-bottom: 20px;  

    .card-body {
      display: flex;
      justify-content: space-between;
    }

    .article-card-left {
      .article-actions {
        .article-action-btn {
          cursor: pointer;
          width: 25px;
          
          &:not(:last-child) {
            margin-right: 20px;
          }

        }
      }
    }

    .card-right img {
      max-width: 110px;
      max-height: 130px;
    }
}